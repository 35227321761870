<template>
  <span :class="$style.carret" ref="carret"></span>
</template>

<script>
export default {
  props: {
    position: {
      type: Boolean,
      require: false
    },
    baseAngle: {
      type: Number,
      require: false,
      default: 0
    }
  },

  data() {
    return {
      angle: 0
    };
  },

  // Для верного отображения направления
  mounted() {
    this.angle = this.baseAngle;
    this.$refs.carret.style.transform = `rotate(${this.angle}deg)`;
  },

  // Меняет положение в зависимости от состояния спойлера / дропдауна
  watch: {
    position() {
      const angleRotate = 180;
      this.angle += angleRotate;
      this.$refs.carret.style.transform = `rotate(${this.angle}deg)`;
    }
  }
};
</script>

<style module>
.carret {
  display: inline-block;
  content: '';
  border-top: 0.375rem solid;
  border-right: 0.375rem solid transparent;
  border-bottom: 0;
  border-left: 0.375rem solid transparent;
  margin-left: 1rem;
}
</style>
