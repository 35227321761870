<template>
  <the-wrapper :isSpinner="isSpinner" :isFullWidth="true">
    <slot>
      <the-wrapper>
        <slot>
          <div :class="$style.description">
            <img
              :class="$style.imgRotate"
              ref="rotateDevice"
              src="../../assets/img/rotate-portrait.png"
              alt="Картинка демонстрации как повернуть телефон"
            />
            <div :class="$style.text">
              <span>
                Раздел не доступен в портретном режиме.
              </span>
              <br />
              <br />
              <br />
              <br />
              <p>Пожалуйста, поверните устройство в ландшафтный режим</p>
            </div>
          </div>
          <div :class="$style.content">
            <h2>История вызовов</h2>
            <calls-history-params></calls-history-params>
            <br />
          </div>
        </slot>
      </the-wrapper>
      <div :class="$style.content">
        <calls-history-chart></calls-history-chart>
        <br />
        <br />
        <calls-history-table></calls-history-table>
      </div>
    </slot>
  </the-wrapper>
</template>

<script>
import CallsHistoryParams from '@/components/reports/calls_history/CallsHistoryParams.vue';
import CallsHistoryChart from '@/components/reports/calls_history/CallsHistoryChart.vue';
import CallsHistoryTable from '@/components/reports/calls_history/CallsHistoryTable.vue';
import TheWrapper from '@/components/shared/TheWrapper.vue';
import { ShowCurrentDate } from '@/additional_helpers/dates';
import { mapActions, mapMutations } from 'vuex';

export default {
  data() {
    return {
      isSpinner: true
    };
  },

  created() {
    this.showData()
      .then((result) => this.getData({ vm: this, params: result }))
      .then(() => this.SHOW_LOADING(false));
  },

  components: {
    CallsHistoryParams,
    CallsHistoryChart,
    CallsHistoryTable,
    TheWrapper
  },

  mounted() {
    // Вращение картинки
    let position = 270;
    const delay = 350;

    const rotate = (time) => {
      setInterval(() => {
        if (position === 180) {
          position = 285;
        }
        this.$refs.rotateDevice.style.transform = `rotate(${(position -= 15)}deg)`;
      }, time);
    };

    if (window.innerWidth < 567) {
      rotate(delay);
    } else {
      clearInterval(rotate);
    }
  },

  beforeDestroy() {
    this.CLEAR_TABLE();
  },

  methods: {
    ...mapActions({ getData: 'callsHistory/getData' }),
    ...mapMutations({ SHOW_LOADING: 'SHOW_LOADING', CLEAR_TABLE: 'callsHistory/CLEAR_TABLE' }),

    async showData() {
      const defaultDate = `${ShowCurrentDate()}T00:00:00`;

      const row = {
        IntervalStart: defaultDate,
        IntervalEnd: defaultDate
      };
      return row;
    }
  }
};
</script>

<style module>
@media (orientation: portrait) {
  .content {
    display: none;
  }

  .description {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    background: grey;
  }

  .imgRotate {
    margin-top: 6rem;
    transform: rotate(270deg);
    width: 100px;
  }

  .text {
    padding-top: 6rem;
  }
}

@media (orientation: landscape) {
  .content {
    display: block;
  }

  .description {
    display: none;
  }
}
</style>
