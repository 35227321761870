<template>
  <div :class="$style.paramsWrap" class="info-block_color">
    <info-link @clickLink="showParams">
      <div class="dflex valign-center">
        <div>Параметры</div>
        <carret-arrow v-model="isVisible" :position="isVisible"></carret-arrow>
      </div>
    </info-link>
    <b-collapse v-model="isVisible">
      <br />
      <b-form @submit.prevent="getCallsHistory">
        <b-row align-h="between">
          <b-col sm="6" lg="6" xl="4" v-for="(group, groupName) in checkboxes" :key="groupName">
            <b-form-group :label="labels[groupName]">
              <div v-for="direction in group" :key="direction.value" :class="$style.formInputsWrap">
                <b-form-checkbox v-model="direction.enabled" :id="direction.id">
                  {{ direction.label }}
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>
          <b-col lg="8" xl="4" class="mt-4 mt-xl-0">
            <b-form-group
              label="Начало периода:"
              label-cols="3"
              label-cols-md="5"
              label-align-xl="right"
            >
              <BaseInputDate v-model="dateStart"/>
            </b-form-group>
            <b-form-group
              label="Конец периода:"
              label-cols="3"
              label-cols-md="5"
              label-align-xl="right"
            >
              <BaseInputDate v-model="dateEnd"/>
            </b-form-group>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col>
            <p>
              <b>Номер телефона</b>
            </p>
            <div :class="$style.numbersList" class="dflex">
              <div v-for="number in userNumbers" :key="number.Name" :class="$style.phoneNumberWrap">
                <b-form-checkbox v-model="number.Enabled" :id="number.Name">
                  {{ number.Name }}
                </b-form-checkbox>
              </div>
            </div>
            <BaseButton @click.stop.prevent="selectAll">
              <template #text>
                {{ isSelectedAll ? 'Выбрать все номера' : 'Выбрать один номер' }}
              </template>
            </BaseButton>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="dflex align-end">
            <BaseButton>
              <template #text>
                Показать статистику
              </template>
            </BaseButton>
          </b-col>
        </b-row>
      </b-form>
    </b-collapse>
  </div>
</template>

<script>
import InfoLink from '@/components/shared/InfoLink.vue';
import BaseButton from '@/components/shared/buttons/BaseButton.vue';
import CarretArrow from '@/components/shared/CarretArrow.vue';
import { employMaskPhone } from '@/additional_helpers/helpers';
import { ShowCurrentDate } from '@/additional_helpers/dates';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import BaseInputDate from '@/components/shared/inputs/BaseInputDate.vue';

export default {
  data() {
    return {
      angle: 0,
      isVisible: false,
      dateStart: ShowCurrentDate(),
      dateEnd: ShowCurrentDate(),
      phoneNumbers: [],
      labels: { CallDirectionItems: 'Направление вызова', GraphicTypeItems: 'Типы графиков' },
      checkboxes: {
        CallDirectionItems: [
          {
            label: 'Входящие',
            value: 'Входящие',
            id: 'incoming',
            enabled: true
          },
          {
            label: 'Пропущенные',
            value: 'Пропущенные',
            id: 'missed',
            enabled: false
          },
          {
            label: 'Исходящие',
            value: 'Исходящие',
            id: 'outgoing',
            enabled: false
          }
        ],
        GraphicTypeItems: [
          {
            label: 'Количество звонков',
            value: 'Количество звонков',
            id: 'count-calls',
            enabled: true
          },
          {
            label: 'Суммарная продолжительность звонков',
            value: 'Суммарная продолжительность звонков',
            id: 'duration-sum',
            enabled: false
          },
          {
            label: 'Cредняя продолжительность звонков',
            value: 'Cредняя продолжительность звонков',
            id: 'duration-avrg',
            enabled: false
          }
        ]
      }
    };
  },

  components: {
    BaseInputDate,
    InfoLink,
    BaseButton,
    CarretArrow
  },

  computed: {
    ...mapGetters({ table: 'callsHistory/table' }),

    isSelectedAll() {
      return this.userNumbers.some((el, index) => {
        if (!this.userNumbers[0].Enabled) return true;
        if (!el.Enabled && index !== 0) return true;
        return false;
      });
    },

    userNumbers() {
      const numbers = this.table.RequestModel.CustomerNumberItems;
      numbers.map((el) => ({
        label: employMaskPhone(el.Name),
        value: el.Name,
        checked: el.Enabled
      }));
      return numbers;
    }
  },

  methods: {
    ...mapActions({ getCallsData: 'callsHistory/getData' }),
    ...mapMutations({ SHOW_LOADING_SPINNER: 'SHOW_LOADING_SPINNER' }),

    selectAll() {
      const selected = this.userNumbers.some((el) => !el.Enabled);

      if (selected) {
        this.selectItems(false, true);
      } else {
        this.selectItems(true, false);
      }
    },

    selectItems(oldValue, newValue) {
      this.userNumbers.forEach((el, index) => {
        if (el.Enabled === oldValue && index !== 0) {
          this.userNumbers[index].Enabled = newValue;
        } else if (!el.Enabled && index === 0) {
          this.userNumbers[index].Enabled = newValue;
        }
      });
    },

    getCallsHistory() {
      const { checkboxes } = this;

      const CustomerNumberItems = this.userNumbers.map((el) => ({
        Name: el.Name,
        Enabled: el.Enabled
      }));

      const directionAndGraphicType = Object.values(checkboxes).map((el) => el.map((item) => ({
        Name: item.label,
        Enabled: item.enabled
      })));

      const row = {
        IntervalStart: `${this.dateStart}T00:00:00`,
        IntervalEnd: `${this.dateEnd}T00:00:00`,
        CustomerNumberItems,
        CallDirectionItems: directionAndGraphicType[0],
        GraphicTypeItems: directionAndGraphicType[1]
      };

      this.SHOW_LOADING_SPINNER(true);
      this.getCallsData({ params: row });
    },

    showParams() {
      this.isVisible = !this.isVisible;
    }
  }
};
</script>

<style module>
.paramsWrap {
  max-width: 70rem;
}

.formInputsWrap:not(:first-child) {
  margin-top: 0.75rem;
}

.phoneNumberWrap {
  padding-bottom: 0.5rem;
}

.phoneNumberWrap {
  padding-left: 1rem;
}

.numbersList {
  padding-bottom: 1rem;
}
</style>
