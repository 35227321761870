<template>
  <div>
    <base-spinner
      v-if="loadingReport"
      :isRequestSpinner="isRequestSpinner"
      :isBaseSpinner="isBaseSpinner"
    ></base-spinner>
    <base-table
      v-else-if="table.RawData.length && !loadingReport"
      :fields="fields"
      :items="table.RawData"
      :isHeader="isHeader"
    >
      <template #thead-top>
        <b-tr>
          <b-th colspan="2"><span class="sr-only">Номер телефона и дата</span></b-th>
          <b-th colspan="5">Входящие</b-th>
          <b-th colspan="3">Исходящие</b-th>
        </b-tr>
      </template>
      <template #cell(GroupDate)="{item}">
        {{ item.GroupDate.replace(/T00:00:00/, ' ') }}
      </template>
    </base-table>
    <base-table v-else-if="!loadingReport && !table.RawData.length" :isHeader="isHeader">
      <slot>
        Данных не найдено
      </slot>
    </base-table>
  </div>
</template>

<script>
import BaseTable from '@/components/shared/BaseTable.vue';
import BaseSpinner from '@/components/shared/BaseSpinner.vue';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isHeader: false,
      isRequestSpinner: true,
      isBaseSpinner: false,
      fields: [
        { key: 'CustomerNumber', label: 'Номер телефона', sortable: true },
        { key: 'GroupDate', label: 'Дата', sortable: true },
        { key: 'InboundAnsweredCallsCount', label: 'Принятые', sortable: true },
        { key: 'InboundMissingCallsCount', label: 'Пропущенные', sortable: true },
        { key: 'InboundCallsDurationAvg', label: 'Средняя продолжительность' },
        { key: 'InboundCallsDurationSum', label: 'Общая продолжительность' },
        { key: 'InboundCallsCount', label: 'Всего', sortable: true },
        { key: 'OutboundCallsDurationAvg', label: 'Средняя продолжительность' },
        { key: 'OutboundCallsDurationSum', label: 'Общая продолжительность' },
        { key: 'OutboundCallsCount', label: 'Всего', sortable: true },
        { key: 'TotalCallsCount', label: 'Всего вызовов', sortable: true }
      ]
    };
  },

  components: {
    BaseTable,
    BaseSpinner
  },

  computed: {
    ...mapGetters({ table: 'callsHistory/table', loadingReport: 'loadingReport' })
  }
};
</script>

<style></style>
