<template>
  <div>
    <highcharts :constructorType="'stockChart'" :options="options"></highcharts>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      browserWidth: window.innerWidth
    };
  },

  mounted() {
    this.setOptions();
  },

  computed: {
    ...mapGetters({ table: 'callsHistory/table', loadingReport: 'loadingReport' }),

    // Преобразует из год-месяц-день в день-месяц-год
    hudDate() {
      const create = (el) => {
        const date = el.replace(/T00:00:00/, '');
        return date
          .split('-')
          .reverse()
          .join('-');
      };
      return {
        intervalStart: create(this.table.RequestModel.IntervalStart),
        intervalEnd: create(this.table.RequestModel.IntervalEnd)
      };
    },

    // Получает максимальное значение Y для графика
    NumberStatistic() {
      const { NumberStats } = this.table;
      const numArray = NumberStats.filter((el) => el.yAxis === 0);
      const a = numArray.map((el) => [el.data.map((item) => item[1])]);
      return { count: Math.max(...a.flat(2)) };
    },

    // По выбранным номерам телефона выбирает данные для построения графика
    columnsData() {
      const { NumberStats } = this.table;
      const getParamData = () => NumberStats.map((el) => ({
        name: el.name,
        data: el.data.map((calls) => ((calls[1] === 0) ? ([calls[0], null]) : calls)),
        type: el.type,
        number: el.number,
        yAxis: el.yAxis,
        cursor: 'pointer',
        animation: true
      }));
      return getParamData();
    },

    // Опции для построения графика
    options() {
      return this.setOptions();
    },

    // Устанавливает настройки для легенды
    legendStyle() {
      if (this.browserWidth < 1200) {
        return {
          align: 'center',
          verticalAlign: 'bottom',
          layout: 'horizontal',
          itemStyle: {
            padding: '5rem'
          }
        };
      }
      return {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemStyle: {
          padding: '10px'
        },
        x: 0,
        y: 100
      };
    }
  },

  methods: {
    calcDate(date) {
      const equalDate = new Date(date);
      const month = equalDate.toLocaleString('ru-RU', { month: 'short' });
      const day = equalDate.getDate();
      return `${day} ${month}`;
    },

    setOptions() {
      const { Yaxises } = this.table;
      const transformDate = this.calcDate;
      const {
        align, verticalAlign, layout, itemStyle
      } = this.legendStyle;

      const chartOptions = {
        chart: {
          renderTo: 'container',
          height: 400
        },
        credits: {
          enabled: false
        },
        title: {
          text: `Анализ звонков за период с
              ${this.hudDate.intervalStart} по
              ${this.hudDate.intervalEnd}`
        },

        // Настройки для xAxis
        xAxis: {
          labels: {
            formatter: (date) => transformDate(date.value)
          }
        },

        // Настройки для yAxis: для каждой из категорий устанавливается отдельно
        // Если будут добавлены категории, то надо добавить настройки для новой оси
        yAxis: [
          {
            labels: { format: this.value },
            title: {
              text: Yaxises.map((el) => el.title.text)[0]
            }
          },
          {
            labels: { format: this.value },
            title: {
              text: Yaxises.map((el) => el.title.text)[2]
            },
            opposite: false
          },
          {
            title: {
              labels: { format: this.value },
              text: Yaxises.map((el) => el.title.text)[1]
            },
            opposite: false
          }
        ],

        // Описание при наведении на точку/столбец на графике
        tooltip: {
          crosshairs: true,
          shared: true,
          formatter() {
            return this.points.reduce(
              (s, point) => `${s}<br/>${point.series.name}: ${point.y}`,
              `<b>${transformDate(this.x)}</b>`
            );
          }
        },

        // Данные, по которым строится график
        series: [...this.columnsData],

        // Настройки легенды
        legend: {
          title: {
            text:
              'Выберите для отображения<br/><span style="font-size: 9px; color: #666; font-weight: normal">(Нажмите, чтобы скрыть)</span>',
            style: {
              fontStyle: 'italic'
            }
          },
          itemStyle,
          itemWidth: 150,
          align,
          verticalAlign,
          layout,
          y: 0,
          enabled: true
        },

        // Языковые настройки
        lang: {
          viewFullscreen: 'Открыть на весь экран',
          downloadJPEG: 'Скачать как изображение JPEG',
          downloadPDF: 'Скачать как документ PDF',
          downloadPNG: 'Скачать как изображение PNG',
          downloadSVG: 'Скачать как векторное изображение SVG',
          loading: 'Загрузка...',
          printChart: 'Распечатать график',
          rangeSelectorZoom: 'Масштаб: ',
          resetZoom: 'Сбросить масштаб'
        },

        // Настройки навигатора внизу графика
        navigator: {
          xAxis: {
            labels: {
              formatter: (date) => transformDate(date.value)
            }
          }
        },

        plotOptions: {
          series: {
            centerInCategory: true
          }
        },

        // Настройки кнопок выбора интервала группировки данных (по дням/неделям и т.д.)
        rangeSelector: {
          allButtonsEnabled: false,
          selected: 0,
          inputEnabled: false,
          buttons: [
            {
              type: 'week',
              count: 1,
              text: 'нед.'

            },
            {
              type: 'month',
              count: 1,
              text: 'мес.'

            },
            {
              type: 'all',
              count: 1,
              text: 'всё'

            }
          ]
        },

        exporting: {
          enabled: true
        }
      };

      return chartOptions;
    }
  }
};
</script>
